





































import { Component, Prop, Watch } from 'vue-property-decorator';
import VueBaseActionButtonWidget from '@/components/action-buttons/VueBaseActionButtonWidget';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { namespace } from 'vuex-class';
import GUUID from '@/utils/GUUID';
import { CommunityUserBookmarkFilter } from '@/graphql/_Filters/CommunityUserBookmarkFilter';
import CommunityUserBookmark from '@/models/graphql/CommunityUserBookmark';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';

const communityUserFollowStore = namespace('CommunityUserFollowStore');

@Component({
  components: {
    ButtonComponent,
    FontAwesomeComponent,
  },
})
export default class ActionButtonFollow extends VueBaseActionButtonWidget {
  @communityUserFollowStore.Action
  follow!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @communityUserFollowStore.Action
  unFollow!: (payload: CommunityUserBookmarkFilter) => Promise<CommunityUserBookmark | undefined>;

  @Prop({ required: true })
  protected readonly actionResult!: string | null;

  @Prop({
    required: false,
    default: false,
  })
  protected readonly autoFollow!: boolean;

  @communityUserFollowStore.Getter
  private isLoading!: boolean;

  private isFollowed: string | null = this.actionResult;

  private get text(): string {
    return `${this.isFollowed ? this.$t('actions.following') : this.$t('actions.follow')}`;
  }

  private get variant(): string {
    return this.isFollowed ? 'alt-2' : 'secondary';
  }

  private get icon(): string {
    return this.isFollowed ? 'fa-regular fa-check' : 'fa-regular fa-plus';
  }

  private get isDisplayed(): boolean {
    return (this.actionType === this.ActionButtonType.FOLLOW
        && !(this.autoFollow && this.entityType === this.EntityTypeEnum.SUB_EDITION));
  }

  created(): void {
    this.buttonsDisplay();
  }

  @Watch('reRender')
  private buttonsDisplay(): void {
    this.commit('updateButtons', {
      index: this.index,
      visible: this.isDisplayed,
    });
  }

  @Watch('actionResult')
  private syncActionResultData(): void {
    this.isFollowed = this.actionResult;
  }

  private toggleFollow(): void {
    if (!this.authUser) {
      this.$bvModal.show('sign-in-action-modal');
      return;
    }
    if (this.isFollowed) {
      const backupValue = this.isFollowed;
      this.isFollowed = null;
      this.unFollow({ uid: backupValue })
        .catch(() => {
          this.isFollowed = backupValue;
        });
    } else {
      this.isFollowed = GUUID.uuidv4();
      this.follow({
        userId: this.authUser.uid,
        linkedUserId: this.entityUid,
        entityType: this.entityType,
      })
        .then((response) => {
          if (response) {
            this.logStats(StatLoggerCategories.FOLLOW, StatLoggerActions.ADD, '');
            this.isFollowed = response.uid;
          } else {
            this.isFollowed = null;
          }
        })
        .catch(() => {
          this.isFollowed = null;
        });
    }
  }
}
